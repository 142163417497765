import {createReport} from "docx-templates";
import {sortPlanExercisesByPosition, sortStages} from "../helperFunctions";
import GroupTrainingAttendanceService from "../../../../../services/grouptrainingattendance.service";

/**
 * Helper function to export exercises in pdf
 * @param plan plan that will be exported in form of the doc sheet
 * @returns {Promise<void>} returns doc sheet in word format
 * @author KMU
 */
export async function exportDocSheet(plan) {
    const blob = await (await fetch("template.docx")).blob();
    const template = await blob.arrayBuffer();

    plan.exercises = sortPlanExercisesByPosition(plan.exercises)
    let planExesExport = [];
    plan.exercises?.forEach((planExe) => {
       planExesExport.push({
           name: planExe.exercise.name,
           desc: planExe.exercise.description,
           mat: planExe.exercise.material,
           method: planExe.exercise.methods.map(m => m.code).join(', '),
           exe: planExe.execution ? planExe.execution : "",
           interest: planExe.interest
       })
    });

    let participants = [];
    //get attended participants if it was a group training
    if (plan.participant === undefined || plan.participant === null){
        const attendances = (await (GroupTrainingAttendanceService.getByPlan(plan.id))).data;
        attendances.forEach(attendance => {
            if (attendance.attended){
                participants.push(attendance.participant.firstname + " " + attendance.participant.lastname)
            }
        })
    } else {
        participants.push(plan.participant.firstname + " " + plan.participant.lastname)
    }

    // list of participant specific notes
    let participant_notes = [];
    plan.notes.forEach(n => {
        if (n.participant !== null){
            participant_notes.push(n.participant.firstname + " " + n.participant.lastname + ": " +
            n.content)
        }
    })

    const date = new Date(plan.date);
    const formatter = new Intl.DateTimeFormat('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    const docx = await createReport({
        template: template,
        data: {
            topic: plan.topic.name,
            date: formatter.format(date),
            service_center: (plan.group === undefined || plan.group === null) ?
                plan.participant.serviceCenter.name : plan.group.serviceCenter.name,
            stages: plan.stages.map(s => s.name).sort((a, b) => sortStages(a, b)).join('/'),
            duration: plan.duration,
            time_content: plan.prepTimeContent,
            time_org: plan.prepTimeOrg,
            time_support: (plan.timeSupport === undefined || plan.timeSupport === null) ? 0 : plan.timeSupport,
            participants: participants.join(', '),
            trainer_name:plan.user.firstname + " " + plan.user.lastname,
            group_name: (plan.group === undefined || plan.group === null) ? "" : plan.group.name,
            planExercises: planExesExport,
            plan_note: plan.notes.find(n => n.group === null && n.participant === null)?.content,
            group_note: plan.notes.find(n => n.group !== null)?.content,
            participant_notes: participant_notes
        },
        noSandbox: true
    });

    let name = ""
    if (plan.participant !== null)
        name = plan.participant.firstname + " " + plan.participant.lastname
    else if (plan.group !== null)
        name = plan.group.name
    saveDataToFile(
        docx,
        'Dokublatt_'+name+"_"+ new Date(plan.date)
            .toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
            .replace(/\./g, '_')
            +'.docx',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
}

/**
 * Save given data in file with given mime type
 * @param data data to save
 * @param fileName name of the new file
 * @param mimeType mime type of the new file
 */
const saveDataToFile = (data, fileName, mimeType) => {
    const blob = new Blob([data], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    downloadURL(url, fileName);
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, 1000);
};

/**
 * Temporary link to download the file
 * @param data data to download in file format
 * @param fileName name of the new file
 */
const downloadURL = (data, fileName) => {
    let a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
};