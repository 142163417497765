import React, {useState} from "react";
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import {
    GroupTrainingAttendance,
    Participant,
    ServiceCenter,
    Stage,
    Topic,
    Traininggroup
} from "../../../../types/domain";
import {FormWrapper} from "../utils/form/FormWrapper";
import {sortStages} from "../utils/helperFunctions";
import {Checkbox, Group, NumberInput} from "@mantine/core";

/**
 * Form element to set the general information of plan
 * that is reworked
 * @author KMU
 */

type GeneralData = {
    topic: Topic,
    stages: Array<Stage>,
    serviceCenter: ServiceCenter,
    trainingGroup: Traininggroup | null,
    participant: Participant | null,
    date: string,
    prepTimeContent: number,
    prepTimeOrg: number,
    duration: number,
    timeSupport: number,
    trainingAttendances: Array<GroupTrainingAttendance>
}

//parent property that will be used in this element
type GeneralFormProps = GeneralData & {
    updateFields: (fields: Partial<GeneralData>) => void
}

export function GeneralForm({topic, stages, serviceCenter, trainingGroup, participant, date,
                                prepTimeContent, prepTimeOrg, duration, timeSupport, trainingAttendances,
                                updateFields}: GeneralFormProps) {

    const [selParticipants, setSelParticipants] = useState<string[]>(initSelParticipants(trainingAttendances))

    function initSelParticipants(trainingAttendances: GroupTrainingAttendance[]){
        let selParts: string[] = [];
        trainingAttendances.forEach(a => {
            if (a.attended){
                selParts.push(a.participant.firstname + ";" + a.participant.lastname)
            }
        });
        return selParts;
    }


    /**
     * Change event for the participant checkboxes that are visible
     * if it is group training
     * @param selValues array of selected participants
     */
    function participantChangeEvent(selValues: string[]) {
        setSelParticipants(selValues)

        trainingAttendances.forEach(a => {
            const mergedName = a.participant.firstname + ";" + a.participant.lastname;
            a.attended = selValues.includes(mergedName);
        })

        updateFields({trainingAttendances: trainingAttendances});
    }

    return (
        <FormWrapper title="Allgemeine Infos">
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Form.Label htmlFor="serviceCenter">Demenzservicestelle</Form.Label>
                        <br/>
                        {serviceCenter.name}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Form.Label htmlFor="date">Datum</Form.Label>
                        <br/>
                        {date === "" ? date :
                            new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Form.Label htmlFor="trainingKind">Trainingsart</Form.Label>
                        <br/>
                        {participant !== null ? "Einzeltraining" : "Gruppentraining"}
                    </FormGroup>
                </Col>
                <Col>
                    {trainingGroup !== null ?
                    <FormGroup className="newExerciseField">
                        <Form.Label htmlFor="group">Gruppe</Form.Label>
                        <br/>
                        {trainingGroup?.name}
                    </FormGroup>
                        : <></>
                    }
                    {participant !== null ?
                        <FormGroup className="newExerciseField">
                            <Form.Label htmlFor="participant">Teilnehmer</Form.Label>
                            <br/>
                            {participant?.firstname + " " + participant.lastname}
                        </FormGroup>
                        : <></>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Form.Label>Stadium</Form.Label>
                        <br/>
                        {stages.map(s => s.name).sort((a, b) => sortStages(a, b)).join('/')}
                    </FormGroup>
                </Col>
            </Row>
            {
                trainingGroup !== null ?
                <Row>
                    <FormGroup className="newExerciseField">
                        <>
                            <Checkbox.Group
                                value={selParticipants}
                                onChange={participantChangeEvent}
                                label="Anwesende Teilnehmer"
                                withAsterisk
                            >
                                <Group>
                                    {
                                        trainingAttendances.map(attendance =>
                                            <Checkbox
                                                label={attendance.participant.firstname + " " + attendance.participant.lastname}
                                                value={attendance.participant.firstname + ";" +  attendance.participant.lastname}
                                            />
                                        )
                                    }
                                </Group>
                            </Checkbox.Group>
                        </>
                    </FormGroup>
                </Row> : <></>
            }
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <NumberInput
                            min={0}
                            step={0.25}
                            max={trainingGroup !== null ? 2.5 : 2}
                            precision={2}
                            decimalSeparator=','
                            id="duration"
                            value={duration}
                            defaultValue={duration}
                            onChange={val => updateFields({duration: val === '' ? 0 : val})}
                            label="Trainingsdauer [Std.]"
                            withAsterisk
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="newExerciseField">
                        <NumberInput
                            min={0}
                            step={0.25}
                            max={trainingGroup !== null ? duration * 0.5 : 0.5}
                            precision={2}
                            decimalSeparator=','
                            id="prepTimeContent"
                            value={prepTimeContent}
                            defaultValue={prepTimeContent}
                            onChange={val => updateFields({prepTimeContent: val === '' ? 0 : val})}
                            label="Vorbereitungszeit (Inhalt) [Std.]"
                            withAsterisk
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <NumberInput
                            min={0}
                            step={0.25}
                            max={0.5}
                            precision={2}
                            decimalSeparator=','
                            id="prepTimeOrg"
                            value={prepTimeOrg}
                            defaultValue={prepTimeOrg}
                            onChange={val => updateFields({prepTimeOrg: val === '' ? 0 : val})}
                            label="Vorbereitungszeit (Org.) [Std.]"
                            withAsterisk
                        />
                    </FormGroup>
                </Col>
                <Col>
                    {trainingGroup !== null ?
                        <FormGroup className="newExerciseField">
                            <NumberInput
                                min={0}
                                step={0.25}
                                max={0.5}
                                precision={2}
                                decimalSeparator=','
                                id="timeSupport"
                                value={timeSupport}
                                defaultValue={timeSupport}
                                onChange={val => updateFields({timeSupport: val === '' ? 0 : val})}
                                label="Zeit für Praktikumsbegleitung [Std.]"
                                withAsterisk
                            />
                        </FormGroup>
                        : <></>
                    }
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup className="newExerciseField">
                        <Form.Label htmlFor="topic">Thema</Form.Label>
                        <br/>
                        {topic.name}
                    </FormGroup>
                </Col>
            </Row>
        </FormWrapper>
    )
}