import "../../css/views/header/MainNavigation.css"
import {Link} from "react-router-dom";

type Props = {
    height: number,
    gap: string,
    fontSize: string,
    inHeader: boolean
}
export default function MainNavigation(props: Props){
    let marginRight = "0";
    if (props.inHeader){
        marginRight = "20rem"
    }

    return (
        <div style={{display: "flex", gap: props.gap, alignItems: "center", marginRight: marginRight}}>
            {
                props.inHeader ? <div className="icon-text">
                    <Link to="/">
                        <img src={require('./../../assets/icons/home.png')} alt="home-icon"
                             height={props.height}/>
                    </Link>
                    <p style={{textAlign: "center", fontSize: props.fontSize, margin: 0}}>
                        <b>Home</b>
                    </p>
                </div> : <></>
            }
            <div className="icon-text">
                <Link to="/tyb">
                    <img src={require('./../../assets/icons/TYBO.png')} alt="TYBO-icon"
                         height={props.height}/>
                </Link>
                <p style={{textAlign: "center", fontSize: props.fontSize, margin: 0}}>
                    <b>TrainYourBrain</b>
                </p>
            </div>
            <div className="icon-text">
                <Link to="/login">
                    <img src={require("../../assets/icons/login.png")} alt="login-icon"
                         height={props.height}/>
                </Link>
                <p style={{textAlign: "center", fontSize: props.fontSize, margin: 0}}>
                    <b>Login</b>
                </p>
            </div>
            <div className="icon-text">
                <Link to="/impressum">
                    <img src={require("../../assets/icons/Impressum.png")} alt="impressum-icon"
                         height={props.height}/>
                </Link>
                <p style={{textAlign: "center", fontSize: props.fontSize, margin: 0}}>
                    <b>Impressum</b>
                </p>
            </div>
            <div className="icon-text">
                <Link to="/contact">
                    <img src={require("../../assets/icons/Team.png")} alt="team-icon"
                         height={props.height}/>
                </Link>
                <p style={{textAlign: "center", fontSize: props.fontSize, margin: 0}}>
                    <b>Kontakt</b>
                </p>
            </div>
        </div>
    );
}