import Logo from "../header/Logo";
import MainNavigation from "../header/MainNavigation";
import React from "react";
import {Grid} from "@mantine/core";
import {Col} from "react-bootstrap";

export default function Contact() {
    const height = 200;
    return(
        <div className="contact">
            <div className="header">
                <Logo bigLogo={true}/>
                <div style={{display: "flex", gap: "4rem"}}>
                    <MainNavigation height={60} gap={"1rem"}
                                    fontSize={"1rem"} inHeader={true}/>
                </div>
            </div>
            <div className="page-image-div">
                <img src={require("../../assets/images/image-mannlinks.png")}
                     alt="dashboard-icon"
                     className="page-image"
                />
            </div>
            <div className="content" style={{padding: "2rem 4rem", display: "flex", flexDirection: "column"}}>
                <h1><b>Kontakt</b></h1>
                <Grid style={{margin: 0, marginTop: "1rem"}}>
                    <Col>
                        <p>Mag. Gerald Kienesberger<br/>
                            Geschäftsführung MAS Alzheimerhilfe<br/>
                            <a href="mailto:gerald.kienesberger@mas.or.at">gerald.kienesberger@mas.or.at</a><br/></p>
                        <p>MAS Alzheimerhilfe | Lindaustraße 28 (Eingang A, 2. Stock) | 4820 Bad Ischl |
                            Österreich <br/>
                            Tel.: +43 / 6132 / 214 10 | Fax: +43 / 6132 / 214 10-10 <br/>
                            <a href="mailto:alzheimerhilfe@mas.or.at">alzheimerhilfe@mas.or.at </a>
                            | <a href="https://www.alzheimerhilfe.at"
                                                          target="_blank">www.alzheimerhilfe.at</a></p>
                        <p>
                            So kommen Sie direkt zu den 7 MAS Demenzservicestellen:<br/>
                            <b>DSS Bad Ischl</b> | Tel.: 0664 / 889 28 619 | <a href="mailto:dss.bad-ischl@mas.or.at">dss.bad-ischl@mas.or.at</a><br/>
                            <b>DSS Braunau</b> | Tel.: 0664 / 458 00 71 | <a href="mailto:dss.braunau@mas.or.at">dss.braunau@mas.or.at</a><br/>
                            <b>DSS Gmunden</b> | Tel.: 0664 / 858 94 85 | <a href="mailto:dss.gmunden@mas.or.at">dss.gmunden@mas.or.at</a><br/>
                            <b>DSS Linz Nord/Urfahr</b> | Tel.: 0664 / 213 99 77 | <a href="mailto:dss.linz-nord@mas.or.at">dss.linz-nord@mas.or.at</a><br/>
                            <b>DSS Kirchdorf/Micheldorf</b> | Tel.: 0664 / 854 66 94 | <a href="mailto:dss.micheldorf@mas.or.at">dss.micheldorf@mas.or.at</a><br/>
                            <b>DSS Ried/I.</b> | Tel.: 0664 / 854 66 92 | <a href="mailto:dss.ried-im-innkreis@mas.or.at">dss.ried-im-innkreis@mas.or.at</a><br/>
                            <b>DSS Rohrbach</b> | Tel.: 0664 / 854 66 99 | <a href="mailto:dss.rohrbach@mas.or.at">dss.rohrbach@mas.or.at</a><br/>
                        </p>
                        <a href="https://www.alzheimerhilfe.at/" target="_blank" rel="noreferrer">
                            <img src={require('./../../assets/logo/MAS-Alzheimerhilfe-Logo.png')} alt="MAS-logo"
                                 height={"60"}/>
                        </a>
                    </Col>
                    <Col>
                        <div>
                            <p>FH-Assistenzprof. Susanne Schaller, MMSc und Katharina Munk, MSc<br/>
                                Forschungsgruppe Bioinformatik, FH OÖ Campus Hagenberg<br/>
                                <a href="mailto:susanne.schaller@fh-hagenberg.at">susanne.schaller@fh-hagenberg.at </a>
                                | <a href="mailto:katharina.munk@fh-hagenberg.at">katharina.munk@fh-hagenberg.at</a> <br/>
                                Softwarepark 11-13 | 4232 Hagenberg im Mühlkreis<br/>
                                <a href="https://bioinformatics.fh-hagenberg.at"
                                   target="_blank" rel="noreferrer">www.bioinformatics.fh-hagenberg.at</a>
                            </p>

                            <p>FH-Prof. Mag. Dr. Renate Kränzl-Nagl und Stephanie Schwarz BSc MSSc<br/>
                                Forschungsgruppe IMPACT, FH ÖO Campus Linz<br/>
                                <a href="mailto:renate.kraenzl-nagl@fh-linz.at">renate.kraenzl-nagl@fh-linz.at </a>
                                | <a href="mailto:stephanie.schwarz@fh-linz.at">stephanie.schwarz@fh-linz.at</a><br/>
                                Garnisonstraße 21 | 4020 Linz
                            </p>
                            <a href="https://fh-ooe.at/" target="_blank" rel="noreferrer">
                                <img src={require("../../assets/logo/FH_Logo_OOE.png")} alt="FH-icon"
                                     height={"60"} style={{marginRight: "1rem"}}/>
                            </a>
                            <a href="https://fh-ooe.at/organisation/fh-ooe-forschung-und-entwicklung" target="_blank">
                                <img src={require("../../assets/logo/FHO_FE_Logo_DE EPS.png")} alt="FH-F&E-icon"
                                     height={"60"}/>
                            </a>
                        </div>
                    </Col>
                </Grid>
            </div>
        </div>
    );
};