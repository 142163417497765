import http from "./http-common"

/**
 * Service class for accessing the FTP server
 * @author KMU
 */
class FTPService {
    url = `/ftp/`;

    getFiles(paths: Array<string>) {
        return http.post(this.url + `getFiles`,
            paths, {responseType: 'blob'});
    }

    uploadFile(file: File, dirName: string){
        let formData = new FormData();
        formData.append("file", file);
        formData.append("dirName", dirName);
        return http.post(this.url + `uploadFiles`,
            formData, {headers: {
                "Content-Type": 'multipart/form-data'
                }})
    }
}

export default new FTPService();