import {Exercise, ExerciseAttachments, ExerciseTableData} from "../../../../types/domain";
import React, {useEffect, useMemo, useState} from "react";
import {ExerciseCreatePanel} from "../utils/ExerciseCreatePanel";
import {MantineReactTable, MRT_ColumnDef, MRT_Row, useMantineReactTable} from "mantine-react-table";
import {MRT_Localization_DE} from "mantine-react-table/locales/de";
import {ActionIcon} from "@mantine/core";
import {IconTrash} from "@tabler/icons-react";
import {generateExerciseTableData} from "../utils/helperFunctions";
import TopicService from "../../../../services/topic.service";

/**
 * @author KMU
 * Form element to create the new exercises
 */

type ExercisesData = {
    newExercises: Array<Exercise>,
    topic: string,
    newAttachments: Array<ExerciseAttachments>
}

//parent property that will be used in this element
type ExercisesFormProps = ExercisesData & {
    updateFields: (fields: Partial<ExercisesData>) => void
}

export function ExercisesForm({newExercises, topic, newAttachments,
                                updateFields}: ExercisesFormProps) {

    const [data, setData] = useState<Array<ExerciseTableData>>(generateExerciseTableData(newExercises, newAttachments));
    const [topicExercises, setTopicExercises] = useState<Array<Exercise>>([]);

    //definition of table columns
    const columns = useMemo<MRT_ColumnDef<ExerciseTableData>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Übung/Tätigkeit'
            },
            {
                accessorKey: 'description',
                header: 'Beschreibung',
                size: 300
            },
            {
                accessorKey: 'method',
                header: 'Methode',
                size: 50,
                mantineTableHeadCellProps: {
                    align: 'center',
                },
                mantineTableBodyCellProps: {
                    align: 'center',
                }

            },
            {
                accessorKey: 'material',
                header: 'Material'
            },
            {
                accessorKey: 'attachments',
                header: 'Anhänge'
            }
        ], []
    );

    /**
     * React to change of new exercises
     */
    useEffect(() => {
        // init table
        let tableData = generateExerciseTableData(newExercises, newAttachments)
        setData([... tableData]);
    }, [JSON.stringify(newExercises)]);

    /**
     * React to topic change
     */
    useEffect(() => {
        if (topic !== ""){
            //topic entity
            TopicService.getByName(topic).then((response: any) => {
                //get exercises of entity
                if (response.data.id != undefined){
                    TopicService.getExercises(response.data.id).then((response: any) => {
                        setTopicExercises(response.data);
                    }).catch((e: Error) => {
                        console.log(e);
                    });
                }
            }).catch((e: Error) => {
                console.log(e);
            });
        }
    }, [topic]);

    /**
     * Delete exercise from list of new exercises
     * @param name exercise name
     * @param desc exercise description
     * @param material exercise material
     */
    function deleteExercise(name: string, desc: string, material: string){
        newExercises = newExercises.filter(e => e.name !== name || e.description !== desc || e.material !== material)
        newAttachments = newAttachments.filter(a => a.exercise.name !== name || a.exercise.description !== desc || a.exercise.material !== material)
        updateFields({newExercises: newExercises, newAttachments: newAttachments})
        setData([... generateExerciseTableData(newExercises, newAttachments)]);
    }

    /**
     * Reorder exercises according to table data
     * @param tableData exercises in table data form
     * @param exercises new exercises
     */
    function reOrderExercises(tableData: Array<ExerciseTableData>, exercises:Array<Exercise>){
        let reorderedExes:Array<Exercise> = []
        tableData.forEach(row => {
            const exercise = exercises.find(e => e.name === row.name
                && e.description === row.description && e.material === row.material)
            if (exercise){
                reorderedExes.push(exercise);
            }
        })
        updateFields({newExercises: reorderedExes});
    }

    const table = useMantineReactTable<ExerciseTableData>({
        columns,
        data,
        enableColumnActions: false,
        enableTopToolbar: false,
        enableBottomToolbar: false,
        enableRowActions: true,
        positionActionsColumn: "last",
        renderRowActions: ({ row }) => {
            return <ActionIcon color="red" onClick={() => deleteExercise(row.original.name, row.original.description, row.original.material)}>
                <IconTrash/>
            </ActionIcon>
        },
        localization: MRT_Localization_DE,
        paginationDisplayMode: "pages",
        enableRowOrdering: true,
        mantineRowDragHandleProps: {
            onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                    data.splice(
                        (hoveredRow as MRT_Row<ExerciseTableData>).index,
                        0,
                        data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                    reOrderExercises(data, newExercises);
                }
            },
        }
    });

    return (
        <>
            <ExerciseCreatePanel newExercises={newExercises} topicExercises={topicExercises}
                                 newAttachments={newAttachments} updateFields={updateFields}/>
            <hr></hr>
            <div className="exerciseListTable">
                <h4 className="heading">Geplante Tätigkeiten</h4>
                <MantineReactTable
                    table={table}
                />
            </div>
        </>
    )
}