import React, {SyntheticEvent, useEffect, useState} from "react";
import '../../css/components/NewPlan.css';
import {useMultistepForm} from "./components/utils/form/useMultistepForm";
import {GeneralForm} from "./components/newPlanSteps/GeneralForm";
import {Attachment, Exercise, Plan, PlanExercise, PlanFormData} from "../../types/domain";
import {ExercisesForm} from "./components/newPlanSteps/ExercisesForm";
import {useLocation, useNavigate} from 'react-router-dom';
import TopicService from "../../services/topic.service";
import StateService from "../../services/state.service";
import CustomModal from "./components/utils/modal/CustomModal";
import {exportExerciseList} from "./components/utils/export/exportExerciseList";
import PlanService from "../../services/plan.service";
import {Button} from "@mantine/core";
import AppHeader from "../header/AppHeader";
import {Container} from "react-bootstrap";
import {ModalType} from "../../types/UITypes";
import Cookies from "js-cookie";
import UseraccountService from "../../services/useraccount.service";
import {exportAttachments} from "./components/utils/export/exportAttachments";
import FtpService from "../../services/ftp.service";
import {adaptFileName, standardizeTopicName} from "./components/utils/helperFunctions";

/**
 * New plan page
 * @author KMU
 */

const INITIAL_DATA: PlanFormData = {
    topic: "",
    stages: [],
    serviceCenter: {
        id: 0,
        name: ""
    },
    trainingGroup: null,
    participant: null,
    date: "",
    prepTimeContent: 0,
    prepTimeOrg: 0,
    duration: 0,
    timeSupport: 0,
    newExercises: [],
    newAttachments: []
}

export default function NewPlan() {
    const navigate = useNavigate();

    //state that contains the id of the plan that will be updated if not newly created
    const state = useLocation();

    //form data to be mapped to a plan
    const [data, setData] = useState(INITIAL_DATA)
    const [modal, setModal] = useState<ModalType>({
        show: false,
        title: "",
        body: "",
        decline: false,
        handleOk: (() => {}),
        handleCancel: (() => {})
    })

    const [updatePlan, setUpdatePlan] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    /**
     * Helper function to update the data fields
     * @param fields fields with new values
     */
    function updateFields(fields: Partial<any>) {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }

    /**
     * Update model with content
     * @param modal
     */
    function updateModal(modal: ModalType){
        setModal({
            ...modal,
            show: modal.show,
            title: modal.title,
            body: modal.body,
            decline: modal.decline,
            handleOk: modal.handleOk,
            handleCancel: modal.handleCancel
        })
    }

    //attribute to navigate between the form pages
    const {step,
        isFirstStep,
        isLastStep, back, next } =
        useMultistepForm([          //set form pages
            <GeneralForm {...data} updateFields={updateFields} updateModal={updateModal} updatePlan={updatePlan} />,
            <ExercisesForm {...data} updateFields={updateFields} />,
        ])

    /* Reset newExercises list for exercises form */
    useEffect(() => {
        const dbPlan = state.state;
        if (dbPlan != null){
            let serviceCenter = null;
            if (dbPlan.group != null){
                serviceCenter = dbPlan.group.serviceCenter
            } else if (dbPlan.participant != null){
                serviceCenter = dbPlan.participant.serviceCenter
            }

            let exercises: Array<Exercise> = [];
            dbPlan.exercises.forEach((planExe: PlanExercise) => {
                exercises.push(planExe.exercise)
            })

            setData({...data, topic: dbPlan.topic.name, serviceCenter: serviceCenter,
                stages: dbPlan.stages, trainingGroup: dbPlan.group, participant: dbPlan.participant,
                date: dbPlan.date, prepTimeOrg: dbPlan.prepTimeOrg,
                prepTimeContent: dbPlan.prepTimeContent, timeSupport: dbPlan.timeSupport,
                duration: dbPlan.duration, newExercises: exercises
            })

            setUpdatePlan(true);
        } else {
            updateFields({newExercises: [], newAttachments: [], stages: []})
        }
    }, [])

    /* Checks access of user */
    useEffect(() => {
        if(!Cookies.get('username')) {
            navigate('/login', {replace: true})
        }
    });

    /**
     * Handler function to navigate back to the plan overview page
     */
    const handleBackToOverview = () =>  setModal({...modal,
        show: true,
        title: "Hinweis",
        body: "Achtung, jegliche Eingaben werden nicht gespeichert!",
        decline: true,
        handleOk: () => {
            //reset data
            setData(INITIAL_DATA);
            updateFields({newExercises: [], newAttachments: []})
            setModal({...modal, show: false});
            setUpdatePlan(false);
            navigate('/plans', {replace: true})
        },
        handleCancel: () => setModal({...modal, show: false})
    });

    function showSaveModal(){
        // show success modal and redirect to overview page
        setModal({
            ...modal,
            show: true,
            title: "Hinweis",
            body: "Der Plan wurde erfolgreich zwischengespeichert!",
            decline: false,
            handleOk: () => {
                setModal({...modal, show: false});
                navigate('/plans', {replace: true})
            },
            handleCancel: () => {
            }
        })
    }

    function showFinishModal() {
        // show success modal and redirect to overview page
        setModal({
            ...modal,
            show: true,
            title: "Hinweis",
            body: "Die Planung wurde erfolgreich abgeschlossen!",
            decline: false,
            handleOk: () => {
                setModal({...modal, show: false});
                navigate('/plans', {replace: true})
            },
            handleCancel: () => {
            }
        })
    }

    /**
     * Save plan in database
     * @param e form event
     */
    async function onSubmit(e: SyntheticEvent<HTMLFormElement, SubmitEvent>) {
        e.preventDefault()
        if (!isLastStep) {
            //get dates without time
            let givenDate = new Date(data.date);
            let today = new Date()

            //compare date without time - date cannot be in past
            if (givenDate.setHours(0,0,0,0) < today.setHours(0,0,0,0)) {
                setModal({
                    ...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Das Datum kann nicht in der Vergangenheit liegen!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {
                    }
                })

            //date can be max one year in future
            } else if (givenDate.getFullYear() > today.getFullYear() + 1){  //plan can be in next year the latest
                setModal({
                    ...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Das Datum kann maximal im nächsten Jahr liegen!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {
                    }
                })

            //a stage needs to be selected
            } else if (data.stages.length === 0){
                setModal({...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Es wurde dem Plan kein Stadium zugewiesen!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {}
                })

            //either group or single training needs to be selected
            } else if ((data.trainingGroup === null || data.trainingGroup === undefined || data.trainingGroup?.id === 0)
                        && (data.participant === null || data.participant === undefined || data.participant?.id === 0)) {
                setModal({
                    ...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Es muss eine Gruppe oder ein/e Teilnehmer*in ausgewählt werden!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {
                    }
                })

            //no special characters in the topic name allowed
            } else if(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(data.topic)) {
                setModal({
                    ...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Es dürfen keine Sonderzeichen im Thema vorkommen!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {
                    }
                })
            } else {
                //navigate to next form page
                return next()
            }
        } else {
            setIsSaving(true);

            //at least one exercise needs to be created
            if (data.newExercises.length === 0){
                setModal({...modal,
                    show: true,
                    title: "Fehlermeldung",
                    body: "Der Plan muss Übungen enthalten!",
                    decline: false,
                    handleOk: () => setModal({...modal, show: false}),
                    handleCancel: () => {}
                })
            } else {
                let dbTopic = (await TopicService.getByName(data.topic)).data;

                //topic not in database yet
                if (typeof (dbTopic) === 'string') {
                    dbTopic = {
                        id: 0,
                        name: data.topic
                    }
                }

                //create attachments
                //timestamp for filename adaption
                const t = new Date();
                const timestamp = `${t.getDate().toString().padStart(2,'0')}${(t.getMonth()+1).toString().padStart(2,'0')}${t.getFullYear()%100}_${t.getHours().toString().padStart(2,'0')}${t.getMinutes().toString().padStart(2,'0')}${t.getSeconds().toString().padStart(2,'0')}`;

                data.newAttachments.forEach(exeAttachment => {
                    const exe = exeAttachment.exercise; //attachment exercise
                    const newExe = data.newExercises.find(e => e.name === exe.name
                        && e.description === exe.description && e.material === exe.material); //exercise in list of new exercises

                    //find any attachment that is marked as 'Lösungsblatt'
                    const solution = exeAttachment.attachments.find(a => a.type.name === "Lösungsblatt");

                    let newExeAttachments:Array<Attachment> = [];
                    exeAttachment.attachments.forEach(a => {

                        if (a.type.name !== 'Lösungsblatt'){    //solutions are not saved as separate attachments
                            newExeAttachments.push({
                                id: 0,
                                attachmentType: a.type,
                                path: standardizeTopicName(dbTopic.name) + "/" + adaptFileName(a.name, timestamp),
                                solutionPath: a.type.name === 'Arbeitsblatt' && solution ? standardizeTopicName(dbTopic.name) + "/" + adaptFileName(solution.name, timestamp) : "",
                                stages: data.stages,
                                topic: dbTopic
                            });
                        }
                    });

                    newExe?.attachments.push(... newExeAttachments);
                });

                //get if it is a save or update call
                const submitter = (e.nativeEvent.submitter as HTMLInputElement).name
                let planState;
                if (submitter === "finish") {
                    planState = (await StateService.getByName("im Training")).data;
                } else {
                    planState = (await StateService.getByName("in Planung")).data;
                }

                if (updatePlan) {
                    const dbPlan = state.state;
                    let updatedPlan: Plan = {
                        id: dbPlan.id,
                        date: data.date,
                        duration: data.duration,
                        prepTimeContent: data.prepTimeContent,
                        prepTimeOrg: data.prepTimeOrg,
                        timeSupport: data.timeSupport,
                        exercises: dbPlan.exercises,
                        stages: data.stages,
                        state: planState,
                        topic: dbTopic,
                        group: data.trainingGroup,
                        participant: data.participant,
                        notes: [],
                        user: dbPlan.user,
                        creationDate: Date.now().toString()
                    }

                    await PlanService.updateWithExercises(updatedPlan, data.newExercises, dbPlan.id);
                } else {
                    //get user from database
                    const dbUser = (await UseraccountService.getByName(Cookies.get('username')!)).data;

                    let newPlan: Plan = {
                        id: 0,
                        date: data.date,
                        duration: data.duration,
                        prepTimeContent: data.prepTimeContent,
                        prepTimeOrg: data.prepTimeOrg,
                        timeSupport: data.timeSupport,
                        exercises: null,
                        stages: data.stages,
                        state: planState,
                        topic: dbTopic,
                        group: data.trainingGroup,
                        participant: data.participant,
                        notes: [],
                        user: dbUser,
                        creationDate: Date.now().toString()
                    }

                    await PlanService.createWholePlan(newPlan, data.newExercises);
                }

                //save files of attachments on ftp server
                for (const exeAttachment of data.newAttachments) {
                   for (const a of exeAttachment.attachments) {
                       const adaptedFile = new File([a.file!], adaptFileName(a.file!.name, timestamp), {type: a.file!.type});
                       await FtpService.uploadFile(adaptedFile, standardizeTopicName(dbTopic.name));
                   }
                }

                submitter === "finish" ? showFinishModal() : showSaveModal();

                // reset data
                setData(INITIAL_DATA);
                updateFields({newExercises: [], newAttachments: []})
                setIsSaving(false);
            }
        }
    }

    return (
        <div>
            <AppHeader bioLogo={false} showStartpageIcon={true} showNewPlanIcon={false}
                       showUserIcon={true} showHomeIcon={false}/>
            <Container className="app-content-container">
                <form onSubmit={onSubmit}>
                    {step}
                    <div className="newPlanButtonRow">
                        <Button onClick={isFirstStep ? handleBackToOverview : back}>
                            {isFirstStep ? "Zurück zur Übersicht" : "Zurück"}
                        </Button>
                        <Button type="submit" name="save" disabled={isSaving}>{isLastStep ? "Speichern" : "Weiter"}</Button>
                        {
                            isLastStep ? <Button type="submit" name="finish" disabled={isSaving}>Planung abschließen</Button> : <></>
                        }
                    </div>
                </form>
                <CustomModal title={modal.title}
                             body={modal.body}
                             decline={modal.decline} showModal={modal.show} handleOk={modal.handleOk}
                             handleCancel={modal.handleCancel}
                />
            </Container>
        </div>
    )
}