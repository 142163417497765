import React, {useEffect, useMemo, useState} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from 'mantine-react-table';
import {MRT_Localization_DE} from "mantine-react-table/locales/de";
import {Container, Row} from 'react-bootstrap';
import {Exercise, Plan, PlanExercise} from "../../types/domain";
import PlanService from "../../services/plan.service";
import {useNavigate} from "react-router-dom";
import {exportExerciseList} from "./components/utils/export/exportExerciseList";
import {sortStages} from "./components/utils/helperFunctions";
import {exportDocSheet} from "./components/utils/export/exportDocSheet";
import {ActionIcon, Box, Tooltip} from "@mantine/core";
import {
    IconChecklist,
    IconCopy,
    IconEdit,
    IconFiles,
    IconPencil,
    IconReportSearch,
    IconTrash
} from "@tabler/icons-react";
import AppHeader from "../header/AppHeader";
import Cookies from "js-cookie";
import {exportAttachments} from "./components/utils/export/exportAttachments";
import StateService from "../../services/state.service";
import UseraccountService from "../../services/useraccount.service";
import CustomModal from "./components/utils/modal/CustomModal";
import {ModalType} from "../../types/UITypes";

/**
 * @author KMU
 * Page with the list of saved plans
 */

//Plan type for table
type TablePlan = {
    id: number,
    topicName: string,
    trainingTyp: string,
    groupParticipant: string,
    stages: string,
    date: string,
    state: string,
    creationDate: string
}

export default function PlanList () {

    const navigate = useNavigate();

    const [data, setData] = useState<Array<TablePlan>>([]);  //plans from database

    const [modal, setModal] = useState<ModalType>({
        show: false,
        title: "",
        body: "",
        decline: false,
        handleOk: (() => {}),
        handleCancel: (() => {})
    })

    //definition of table columns
    const columns = useMemo<MRT_ColumnDef<TablePlan>[]>(
        () => [
            {
                accessorKey: 'topicName',
                header: 'Thema'
            },
            {
                accessorKey: 'trainingTyp',
                header: 'Trainingsart'
            },
            {
                accessorKey: 'groupParticipant',
                header: 'Gruppen-/ Teilnehmer*inname'
            },
            {
                accessorKey: 'stages',
                header: 'Stadium'
            },
            {
                accessorKey: 'date',
                header: 'Trainingdatum',
                sortingFn: (rowA, rowB, columnId) => {
                    const a = rowA.original.date.split('-').join('');
                    const b = rowB.original.date.split('-').join('');
                    return a.localeCompare(b);
                },
                Cell: props => {
                    const date = new Date(props.row.original.date);
                    const formatter = new Intl.DateTimeFormat('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    return formatter.format(date);
                }
            },
            {
                accessorKey: 'creationDate',
                header: 'Erstelldatum',
                sortingFn: (rowA, rowB, columnId) => {
                    const a = rowA.original.creationDate.split('-').join('');
                    const b = rowB.original.creationDate.split('-').join('');
                    return a.localeCompare(b);
                },
                Cell: props => {
                    const date = new Date(props.row.original.creationDate);
                    const formatter = new Intl.DateTimeFormat('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    });
                    return formatter.format(date);
                }
            },
            {
                accessorKey: 'state',
                header: 'Status'
            }
        ], []
    );

    /*Checks access of user*/
    useEffect(() => {
        if(!Cookies.get('username')) {
            navigate('/login', {replace: true})
        }
    });

    /**
     * First function that is called
     */
    useEffect(() => {
        retrievePlans();
    }, []);

    /**
     * Navigate to rework plan page
     * @param planId id of the plan to rework
     */
    async function reworkPlan(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data
        navigate('/reworkPlan', {replace: true, state: dbPlan})
    }


    async function updatePlan(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data
        navigate('/newPlan', {replace: true, state: dbPlan})
    }

    /**
     * Export exercises of plan with given id
     * @param planId plan id
     */
    async function exportExercisesEvent(planId: number){
        let dbPlan = (await PlanService.get(planId)).data
        let exercises = Array<Exercise>();
        if (dbPlan.exercises !== null)
            dbPlan.exercises.forEach((planExercise) => {
                exercises.push(planExercise.exercise);
            });

        let name = "";
        let participants = null;
        if (dbPlan.participant !== null)
            name = dbPlan.participant.firstname + " " + dbPlan.participant.lastname
        else if (dbPlan.group !== null)
            name = dbPlan.group.name
            participants = dbPlan.group?.participants

        await exportExerciseList(dbPlan.date, exercises, name, participants);
    }

    /**
     * Export plan in document sheet
     * @param planId plan id
     */
    async function exportAsDocSheet(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data
        await exportDocSheet(dbPlan);
    }

    /**
     * Get all plans from the database and convert to table format
     */
    async function retrievePlans() {
        let plans: Array<Plan> = [];
        await PlanService.getByUsername(Cookies.get('username'))
            .then((response: any) => {
                plans = response.data;
            })
            .catch((e: Error) => {
                console.log(e);
            });

        //convert to table format
        let tablePlans: Array<TablePlan> = [];
        for (const plan of plans) {
            let tablePlan: TablePlan = {
                id: plan.id,
                topicName: plan.topic.name,
                trainingTyp: plan.participant ? "ET" : "GT",
                groupParticipant: plan.participant ? plan.participant.lastname : plan.group ? plan.group.name : "leer",
                stages: plan.stages.map(s => s.name).sort((a, b) => sortStages(a, b)).join('/'),
                date: plan.date,
                state: plan.state.name,
                creationDate: plan.creationDate
            }
            tablePlans.push(tablePlan);
        }

        setData(tablePlans);
    }

    /**
     * Export attachments of plan with given id
     * @param planId plan id
     */
    async function exportAttachmentsEvent(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data

        let attachments: string[] = [];
        // get paths of all attachments and solution sheets
        dbPlan.exercises?.forEach(planExe => {
            planExe.exercise.attachments.forEach(attach => {
                attachments.push(attach.path);
                attachments.push(attach.solutionPath);
            })
        })

        let name = "";
        if (dbPlan.participant !== null)
            name = dbPlan.participant.firstname + " " + dbPlan.participant.lastname
        else if (dbPlan.group !== null)
            name = dbPlan.group.name

        //Avoid downloading empty attachments
        if (attachments.length !== 0){
            await exportAttachments(attachments, name,dbPlan.date);
        } else {
            setModal({
                ...modal,
                show: true,
                title: "Hinweis",
                body: "Für diesen Plan gibt es keine Anhänge!",
                decline: false,
                handleOk: () => {setModal({...modal, show: false})},
                handleCancel: () => {}
            })
        }
    }

    /**
     * Duplicate a plan
     * @param planId plan id of plan to be duplicated
     */
    async function duplicatePlan(planId: number) {
        const dbPlan = (await PlanService.get(planId)).data

        const planState = (await StateService.getByName("in Planung")).data;
        const dbUser = (await UseraccountService.getByName(Cookies.get('username')!)).data;

        let duplicatedPlan: Plan = {
            id: 0,
            date: dbPlan.date,
            duration: dbPlan.duration,
            prepTimeContent: dbPlan.prepTimeContent,
            prepTimeOrg: dbPlan.prepTimeOrg,
            timeSupport: dbPlan.timeSupport,
            exercises: null,
            stages: dbPlan.stages,
            state: planState,
            topic: dbPlan.topic,
            group: dbPlan.group,
            participant: dbPlan.participant,
            notes: [],
            user: dbUser,
            creationDate: Date.now().toString()
        }

        //create list with Exercises
        let exercises: Array<Exercise> = [];
        dbPlan.exercises?.forEach((planExe: PlanExercise) => {
            exercises.push(planExe.exercise)
        })

        await PlanService.createWholePlan(duplicatedPlan, exercises);

        // show success modal and redirect to overview page
        setModal({
            ...modal,
            show: true,
            title: "Hinweis",
            body: "Der Plan wurde erfolgreich dupliziert!",
            decline: false,
            handleOk: () => {
                setModal({...modal, show: false});
                retrievePlans();
            },
            handleCancel: () => {
            }
        })
    }

    /**
     * Delete plan
     * @param id plan id
     */
    async function deletePlan(id: number) {
        setModal({
            ...modal,
            show: true,
            title: "Hinweis",
            body: "Soll der Plan wirklich gelöscht werden?",
            decline: true,
            handleOk: async () => {
                await PlanService.delete(id);
                retrievePlans();
                setModal({...modal, show: false})
            },
            handleCancel: () => {
                setModal({...modal, show: false})
            }
        })
    }

    const table = useMantineReactTable<TablePlan>({
        columns,
        data,
        initialState: {
            sorting: [
                {
                    id: 'creationDate',
                    desc: true,
                }
            ]
        },
        enableRowActions: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        positionActionsColumn: "last",
        renderRowActions: ({ row }) => {
                return <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    {row.original.state === "in Planung" ?
                        <Tooltip label="Bearbeiten">
                            <ActionIcon color="blueish" onClick={() => updatePlan(row.original.id)}><IconPencil/></ActionIcon>
                        </Tooltip> : row.original.state === "im Training" ?
                        <Tooltip label="Nachbearbeiten">
                            <ActionIcon style={{color: "var(--salmon)"}} onClick={() => reworkPlan(row.original.id)}><IconEdit/></ActionIcon>
                        </Tooltip> : <></>
                    }
                    {row.original.state === "abgeschlossen" ?
                        <Tooltip label="Dokublatt">
                            <ActionIcon onClick={() => exportAsDocSheet(row.original.id)}><IconReportSearch/></ActionIcon>
                        </Tooltip> : <>
                            <Tooltip label="Übungsliste">
                                <ActionIcon onClick={() => exportExercisesEvent(row.original.id)}><IconChecklist/></ActionIcon>
                            </Tooltip>
                            <Tooltip label="Anhänge">
                                <ActionIcon onClick={() => exportAttachmentsEvent(row.original.id)}><IconFiles/></ActionIcon>
                            </Tooltip>
                        </>
                    }
                    <Tooltip label="Duplizieren">
                        <ActionIcon onClick={() => duplicatePlan(row.original.id)}><IconCopy/></ActionIcon>
                    </Tooltip>
                    {row.original.state !== "abgeschlossen" ?
                        <Tooltip label="Löschen">
                            <ActionIcon color="red" onClick={() => deletePlan(row.original.id)}><IconTrash/></ActionIcon>
                        </Tooltip> : <></>
                    }
                </Box>
        },
        mantineTableBodyCellProps: ({cell}) => ({
            style: {
                color: cell.getValue() === "in Planung" ? "var(--blueish)" :
                    cell.getValue() === "im Training" ? "var(--salmon)" : "black",
            },
        }),
        localization: MRT_Localization_DE,
        enableColumnActions: false,
        paginationDisplayMode: "pages"
    });

    return (
        <div>
            <AppHeader bioLogo={false} showStartpageIcon={true} showNewPlanIcon={true}
                       showUserIcon={true} showHomeIcon={false}/>
            <Container className="app-content-container">
                <Row className="firstRow heading">
                    <h1>Übersicht Trainingspläne</h1>
                </Row>
                <Row>
                    <div className="planListTable">
                        <MantineReactTable
                            table={table}
                        />
                    </div>
                </Row>
                <CustomModal title={modal.title}
                             body={modal.body}
                             decline={modal.decline} showModal={modal.show} handleOk={modal.handleOk}
                             handleCancel={modal.handleCancel}
                />
            </Container>
        </div>
    );
}