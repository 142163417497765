import {Alert, Button, FileInput, Group, Modal, Radio} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {AttachmentType, UploadAttachment} from "../../../../../types/domain";
import AttachmentTypeService from "../../../../../services/attachmenttype.service";

/**
 * @author KMU
 * Class to define a upload modal
 */

type UploadModalProps = {
    opened: boolean,
    close: () => void,
    attachments: Array<UploadAttachment>
};

export function UploadModal ({opened, close, attachments}: UploadModalProps){
    const [types, setTypes] = useState<AttachmentType[]>([]);
    const [selType, setSelType] = useState<string>("");
    const [selFile, setSelFile] = useState<File | null>(null);
    const [alert, setAlert] = useState({
        show: false,
        message: ""
    });

    useEffect(() => {
        AttachmentTypeService.getAll()
            .then((response: any) => {
                setTypes(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }, []);

    /**
     * Save attachment in temporary list
     */
    function saveUpload() {
        if (!selFile || !selType){
            setAlert({
                show:   true,
                message: "Bitte füllen Sie alle Felder aus!"
            });
        } else if(attachments.find(a => a.type.name === selType && selType === 'Lösungsblatt')){
            setAlert({
                show:   true,
                message: "Es wurde bereits ein Lösungsblatt hochgeladen!"
            });
        } else if(attachments.find(a => a.file?.name === selFile.name)){
            setAlert({
                show:   true,
                message: "Dieses Dokument wurde bereits hochgeladen!"
            });
        } else {
            attachments.push({
                type: types.find(t => t.name === selType)!,
                name: selFile.name,
                file: selFile
            })
            setSelFile(null);
            setSelType("");
            close();
            setAlert({show: false, message: ""});
        }
    }

    return (
        <>
            <Modal opened={opened} onClose={close} title="Anhang hinzufügen">
                <Radio.Group
                    className="newExerciseField"
                    value={selType}
                    onChange={setSelType}
                    label="Art des Anhangs"
                    withAsterisk
                >
                    <Group>
                        {
                            types.map(type =>
                                <Radio
                                    label={type.name}
                                    value={type.name}
                                />
                            )
                        }
                    </Group>
                </Radio.Group>
                <FileInput
                    className="newExerciseField"
                    value={selFile}
                    onChange={setSelFile}
                    placeholder="Datei auswählen"
                    label="Anhang"
                    accept="image/png,image/jpeg,application/pdf"
                    withAsterisk
                />
                <Button onClick={saveUpload}>
                    Hinzufügen
                </Button>
                {
                    alert.show ?
                        <Alert id={'uploadAlert'} w={"100%"} title="Upload fehlgeschlagen" ta={"left"} color="red" mt={"10px"}>
                            {alert.message}
                        </Alert> : <></>
                }
            </Modal>
        </>
    )
}