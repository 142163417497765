import http from "./http-common"
import {Exercise, State} from "../types/domain";

/**
 * Service class for the AttachmentType type
 * @author KMU
 */
class AttachmentTypeService {
    url = `/attachmenttypes/`;

    getAll() {
        return http.get<Array<Exercise>>(this.url + `getAll`);
    }

    /**
     * Get AttachmentType by name
     * @param name name of the AttachmentType
     * @return AttachmentType entity
     */
    getByName(name: string) {
        return http.get<State>(this.url + `getByName/${name}`);
    }
}

export default new AttachmentTypeService();