type Props = {
    bigLogo: boolean
}
export default function Logo(props: Props){
    const height = 100;
    let logoHeight = "100%";
    let marginTop = "0rem";

    if (props.bigLogo){
        logoHeight = "200%";
        marginTop = "4rem";
    }

    return(
        <div style={{display: "flex", marginLeft: "20rem", height: height, alignItems: "center"}} >
            <img src={require('./../../assets/logo/Tybo-Gehirn.png')} alt="TYBO-logo"
                 height={logoHeight} style={{marginTop: marginTop}}/>
            <img src={require("../../assets/logo/Tybo-schrift.png")} alt="TYBO-text"
                 height={"70%"}/>
        </div>
    );
}