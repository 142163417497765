import {PDFDocument} from 'pdf-lib';
import FtpService from "../../../../../services/ftp.service";

/**
 * Helper function to export the attachments in a merged pdf
 * @param attachments names of the attachments
 * @param name name of the group or the participant
 * @param planDate date of the plan
 * @author KMU
 */
export async function exportAttachments(attachments: string[], name: string, planDate: string) {
    const attachmentBuffer = await (await FtpService.getFiles(attachments)).data.arrayBuffer();
    const attachmentDoc = await PDFDocument.load(attachmentBuffer)

    //save pdf in blob
    const pdfBytes = await attachmentDoc.save();
    const blob = new Blob([pdfBytes], {type: "application/pdf"});

    // Automatically download the PDF without user interaction
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Anhänge_" + name + "_" +
        new Date(planDate)
            .toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })
            .replace(/\./g, '_')
        + ".pdf";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url); // Free up memory
}