import React, {useState} from "react";
import {Alert, Button, Paper, PasswordInput, TextInput} from "@mantine/core";
import AppHeader from "../header/AppHeader";
import {Container, Row} from "react-bootstrap";
import {IconLock, IconUser} from "@tabler/icons-react";
import UseraccountService from "../../services/useraccount.service";
import {useNavigate} from "react-router-dom";
import md5 from 'md5';
import Cookies from "js-cookie";

export default function Login() {
    const [nameValue, setNameValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const navigate = useNavigate();
    const [showAlert, setAlert] = useState(false);

    /**
     * Gets the inputs, converts the clear password to md5 and checks if a user with this data
     * exists in the database.
     */
    async function tryLogin(){
        const dbUser = (await UseraccountService.checkLogin({
            username: nameValue,
            password: md5(passwordValue)
        })).data;

        if(dbUser){
            setAlert(false);
            Cookies.set('username', dbUser.username);
            navigate('/home', {replace: true})
        } else{
            setAlert(true);
        }
    }

    /**
     * Event handler for key event that should only try to login the user,
     * when the pressed key is the enter key
     * @param event key down event
     */
    function keyDownEvent(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter"){
            tryLogin()
        }
    }

    return(
        <div className="loginPage">
            <div>
                <AppHeader bioLogo={false} showHomeIcon={true} showNewPlanIcon={false}
                           showUserIcon={true} showStartpageIcon={false}/>
                <Container className="app-content-container">
                        <div className="loginWindow">
                            <Paper shadow="sm" p="xl" withBorder={true} w={"40%"} ta={"center"} ml={"30%"} mt={"7%"}>
                            <Row className="firstRow heading">
                                <h1>Anmeldung</h1>
                            </Row>
                                <TextInput id={"t_username"}
                                           icon={<IconUser size="0.8rem" />}
                                           value={nameValue}
                                           onChange={(event) => setNameValue(event.currentTarget.value)}
                                           placeholder="Benutzername"
                                           onKeyDown={(event) => keyDownEvent(event)}
                                />
                                <PasswordInput  id={"t_password"} pt={"10px"}
                                                icon={<IconLock size="0.8rem" />}
                                                value={passwordValue}
                                                onChange={(event) => setPasswordValue(event.currentTarget.value)}
                                                placeholder="Passwort"
                                                onKeyDown={(event) => keyDownEvent(event)}
                                />
                                {
                                    showAlert ?
                                        <Alert id={'loginAlert'} w={"100%"} title="Einloggen fehlgeschlagen" ta={"left"} color="red" mt={"10px"}>
                                            Bitte überprüfen Sie Benutzername und Passwort!
                                        </Alert> : <></>
                                }
                                <Button onClick={tryLogin} ta={"center"} mt={"10px"}>
                                    Anmelden
                                </Button>
                            </Paper>
                        </div>
                </Container>
            </div>
        </div>
    );
};