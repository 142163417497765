import Logo from "../header/Logo";
import MainNavigation from "../header/MainNavigation";
import LogoFooter from "../footer/LogoFooter";
import "../../css/index.css"
import React from "react";
import {Col} from "react-bootstrap";
import {Grid} from "@mantine/core";


export default function TYB() {
    return(
        <div className="impressum">
            <div className="header">
                <Logo bigLogo={true}/>
                <div style={{display: "flex", gap: "4rem"}}>
                    <MainNavigation height={60} gap={"1rem"}
                                    fontSize={"1rem"} inHeader={true}/>
                </div>
            </div>
            <div className="page-image-div">
                <img src={require("../../assets/images/image-mannrechts.png")}
                     alt="dashboard-icon"
                     className="page-image"
                />
            </div>
            <div className="content" style={{padding: "2rem 4rem", display: "flex", flexDirection: "column"}}>
                <h1><b>TrainYourBrain Optimizer</b></h1>
                <Grid style={{margin: 0}}>
                    <Col>
                        <div className="text-block">
                            <p>TrainYourBrain Optimizer ist eine Software, die speziell für Trainer*innen entwickelt wurde,
                                die Menschen mit Demenzerkrankungen begleiten.
                                Die Software bietet ein umfassendes Tool für die Erstellung von Trainingsplänen,
                                die laufende Überwachung der Übungen und die abschließende Dokumentation,
                                um die Trainer*innen im täglichen Engagement zu unterstützen und deren Arbeit zu erleichtern.</p>
                        </div>

                        <div className="text-block">
                            <h5>Was wir tun:</h5>
                            <p>Unsere Mission ist es, die Arbeit mit Menschen mit Demenz durch innovative digitale Lösungen zu
                                verbessern.
                                Die Software bietet eine Vielzahl von Funktionen, die die Kommunikation, Planung und
                                Dokumentation der zu
                                erstellenden Trainingspläne erleichtern.
                                Der Fokus liegt dabei ganz klar auf der Entlastung der
                                Trainer*innen, sodas sie mehr Kapazitäten für ihre direkte Arbeit mit den Betroffenen haben.</p>
                        </div>

                        <div className="text-block">
                            <h5>Wer wir sind:</h5>
                            <p>
                                Wir sind ein engagiertes Team von Expert*innen, das es sich zur Aufgabe gemacht hat,
                                den Alltag in der Demenzbetreuung durch den Einsatz moderner Technologien zu optimieren.
                                Das Projektteam besteht aus der MAS Alzheimerhilfe, deren Demenzservicestellen niederschwellige
                                Angebote für Betroffene und Angehörige anbieten, und der MAS Ressourcentraining.
                                Es besteht aus engagierten Trainer*innen, Psycholog*innen und Sozialarbeiter*innen. <br/>

                                Für die technische Umsetzung ist das Team der FH Oberösterreich, Campus Hagenberg mit der
                                Projektverantwortlichen Susanne Schaller und der wissenschaftlichen Mitarbeiterin Katharina Munk verantwortlich.
                                Zusätzlich erhielten diese Unterstützung von Carina Kirschner und Natalie Schmidt.
                                Um die Softwareentwicklung mit und für die
                                Trainer*innen optimal zu verwirklichen, übernimmt die FH Oberösterreich, Campus Linz unter der
                                Leitung von Renate Kränzl-Nagl und der wissenschaftlichen Mitarbeiterin Stephanie Schwarz die sozialwissenschaftliche Begleitung.
                                Mit unserer langjährigen Erfahrung und Expertise in den jeweiligen Bereichen können wir
                                unser Know How in der Pflege und im Umgang mit Demenzerkrankungen bündeln und wissen,
                                worauf es in der täglichen Arbeit von Trainer*innen der MAS Ressourcentrainings ankommt.
                            </p>
                        </div>

                        <div className="text-block">
                            <h5>Für wen ist die App gedacht:</h5>
                            <p>Unsere App richtet sich an Trainer*innen und Betreuungspersonen, die Demenzkranke auf ihrem Weg
                                begleiten.
                                Sie unterstützt bei der Planung von Aktivitäten, der Erstellung von Trainingsplänen und der
                                abschließenden Dokumentation.
                                Ziel ist es, die organisatorische und administrative Arbeitsbelastung der Trainer*innen zu
                                reduzieren und ihnen so mehr
                                Zeit und Ressourcen für ihre Arbeit mit den Erkrankten zu ermöglichen.
                            </p>
                        </div>
                    </Col>
                    <Col className="col-4" style={{display: "flex", flexWrap: "nowrap", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                        <img src={require('./../../assets/images/group.png')} alt="group"/>
                        <div style={{fontSize: "smaller", maxWidth: "450px"}}>
                            <span>TrainYourBrain Projektteam (v. u. n. o., v. l. n. r): Julia Wimmer-Elias, Daniela Lugmayr u. Susanne Schaller; </span>
                            <span>Stephanie Schwarz, Elisabeth Hofer u. Renate Kränzl-Nagl; </span>
                            <span>Gerald Kienesberger, Roland Sperling u. Katharina Munk; </span>
                            <i>Foto privat.</i>
                        </div>
                    </Col>
                </Grid>
            </div>
            <LogoFooter/>
        </div>
    );
};